//发表运动打卡
const postHealthUrl = `/gateway/hc-health/miniapi/healthRecord/add`;
//我的运动打卡
const myHealthUrl = `/gateway/hc-health/miniapi/healthRecord/mineList`;
//运动打卡详情
const healthDetailUrl = `/gateway/hc-health/miniapi/healthRecord/detailInfo`;
//社区共建墙
const wishWallUrl = `/gateway/hc-govern/miniapi/communityConstruction/list`;
// 微信运动步数展示接口
const weChatMovementUrl = `/gateway/hc-health/miniapi/healthRecord/weChatMovement`;
// 领取微信运动积分接口
const weChatMovementPointUrl = `/gateway/hc-health/miniapi/healthRecord/weChatMovementPoint`;

export {
  postHealthUrl,
  myHealthUrl,
  healthDetailUrl,
  wishWallUrl,
  weChatMovementUrl,
  weChatMovementPointUrl,
};
